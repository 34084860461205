import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textStepperFamilyRules', 'ButtonDialog_tip'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextStepper = makeShortcode("TextStepper");
const ButtonDialog = makeShortcode("ButtonDialog");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "why-family-rules"
    }}>{`Why family rules?`}</h1>
    <h3 {...{
      "id": "family-rules-is-that-like-living-in-the-army"
    }}>{`Family rules... is that like living in the army?`}</h3>
    <TextStepper id="textStepperFamilyRules" sequenceSpeed={1200} fadeSpeed={1200} list={[{
      text: "If I set rules, they'll resent it - they'll rebel!",
      long: true
    }, {
      text: 'Ugh, I hate that word. Makes me think of getting into trouble when I was this age.',
      long: true
    }]} mdxType="TextStepper" />
    <p>{`No. Family rules are statements that describe how family members behave, look after and treat each other. You don’t need a lot of rules but it is important that your agreed family rules:`}</p>
    <ul>
      <li parentName="ul">{`are `}<strong parentName="li">{`simple`}</strong>{`;`}</li>
      <li parentName="ul">{`set `}<strong parentName="li">{`clear expectations`}</strong>{` for the way people behave and treat each other; and`}</li>
      <li parentName="ul">{`outline the `}<strong parentName="li">{`consequences`}</strong>{` if those expectations aren't met.`}</li>
    </ul>
    <p>{`When a family has a culture of consistent family rules and consequences, it actually helps to strengthen mutual respect and closeness between parent and child. Family rules can also help teenagers feel safe and secure, as a household with reasonable and clear boundaries provides a good sense of security.`}</p>
    <p>{`Family rules can also guide your teenager’s decision making when they are outside of the home. For example, if you have a family rule where your teenager has agreed not to drink alcohol, they are more likely to say ‘no’ when alcohol is offered to them outside the home. Ultimately, family rules teach your teenager a sense of responsibility, as expectations have consequences if they are not met, and this is sense of responsibility is essential in raising them to be great adults.`}</p>
    <ButtonDialog id="tip" linkText="Click here for a TIP!" mdxType="ButtonDialog">
  <p>
    You don’t have to call the rules “The Rules.” Perhaps in your family, you feel your children respond better to
    guidelines, expectations, an agreement, or something else a little softer. You can call them whatever you like!
  </p>
    </ButtonDialog>
    <SingleImage smallGridSize={10} gridSize={10} src="/images/m3/Rules_alochol_a.svg" mdxType="SingleImage" />
    <SingleImage smallGridSize={10} gridSize={10} src="/images/m3/Rules_alcohol_b.svg" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      